<template>
  <a-spin :spinning="state.page_loading">
    <sdPageHeader :title="$t.cargo_records">
      <template #buttons>
        <div class="page-header-actions">
          <router-link :to="{name: 'T.SendManualCargo.Page'}">
            <sdButton
              type="primary"
            >
              <sdFeatherIcons
                type="send"
              />
              &nbsp;{{ $t.cargoForm }}
            </sdButton>
          </router-link>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <Pstates class="pst">
        <div
          class="growth-upward"
          role="button"
        >
          <p>Desi</p>
          <sdHeading as="h1">
            {{ state.details.approvedCbm }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-up"
                size="14"
              /> {{ $t.processed }} </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-upward"
          role="button"
        >
          <p>Fiyat</p>
          <sdHeading as="h1">
            {{ state.details.approvedPrice }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-up"
                size="14"
              /> {{ $t.processed }} </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-downward"
          role="button"
        >
          <p>Desi</p>
          <sdHeading as="h1">
            {{ state.details.unApprovedCbm }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-down"
                size="14"
              /> {{ $t.unprocessed }} </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-downward"
          role="button"
        >
          <p>Fiyat</p>
          <sdHeading as="h1">
            {{ state.details.unApprovedPrice }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-down"
                size="14"
              /> {{ $t.unprocessed }} </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-upward"
          role="button"
        >
          <p>Desi</p>
          <sdHeading as="h1">
            {{ state.details.totalCbm }}
            <sub class="sub">
              <span> <sdFeatherIcons
                icon="arrow-right"
                size="14"
              /> {{ $t.total }} </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-upward"
          role="button"
        >
          <p>Fiyat</p>
          <sdHeading as="h1">
            {{ state.details.totalPrice }}
            <sub class="sub">
              <span> <sdFeatherIcons
                icon="arrow-right"
                size="14"
              /> {{ $t.total }} </span>
            </sub>
          </sdHeading>
        </div>
      </Pstates>

      <sdCards headless>
        <a-button
          type="primary"
          style="margin-bottom: 10px"
          @click="sendForPrint"
        >
          <template #icon>
            <DownloadOutlined />
          </template>
          {{ $t.batch_printing }}
        </a-button>
        <a-table
          :locale="locale"
          :row-selection="state.rowSelection"
          :data-source="state.shipments"
          :columns="state.columns"
          :pagination="state.pagination"
          row-key="id"
          @change="handleTableChange"
        >
          <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="getPlaceholder(column.dataIndex)"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="confirm"
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="confirm"
              >
                <template #icon>
                  <SearchOutlined />
                </template>
                {{ $t.searchText }}
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="clearFilters"
              >
                {{ $t.searchReset }}
              </a-button>
            </div>
          </template>
          <template #filterIcon="filtered">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
          </template>
          <template #receiver_cust_name_render="{ record }">
            <span style="text-transform: uppercase">{{ record.receiver_cust_name }}</span>
          </template>
          <template #shipping_company_name_render="{ record }">
            <span style="text-transform: uppercase">{{ record.shipping_company_name }}</span>
          </template>
          <template #created_at_render="{ record }">
            {{ moment(record.created_at).format('DD.MM.YYYY HH:mm') }}
          </template>
          <template #state_info_render="{ record }">
            <div class="taglist-wrap">
              <a-tag
                :color="record.state_info.color"
              >
                {{ record.state_info.title }}
              </a-tag>
            </div>
          </template>
          <template #tracking_url_render="{ record }">
            <span v-if="record.tracking_url !== null && record.tracking_url !== ''">
              <a-button
                type="primary"
                shape="circle"
                @click="goHref(record.tracking_url)"
              >
                <template #icon>
                  <sdFeatherIcons type="link" />
                </template>
              </a-button>
            </span>
            <span v-else>-</span>
          </template>
          <template #barcode_link_render="{ record }">
            <a-button
              type="dashed"
              @click="singlePrint(record.barcode_link)"
            >
              {{ $t.print }}
            </a-button>
          </template>
          <template #cargo_movements_render="{ record }">
            <a-button
              type="primary"
              @click="cargoMovementClick(record.id)"
            >
              <template #icon>
                <OrderedListOutlined />
              </template>
            </a-button>
          </template>
          <template #cancel_render="{ record }">
            <a-tooltip
              v-if="record.cancelable"
              title="Gönderi İptal"
            >
              <a-button
                :loading="state.cancel_submittings[record.id]"
                type="danger"
                @click="cancelShipment(record.id)"
              >
                <template #icon>
                  <CloseOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </template>
        </a-table>
      </sdCards>

      <a-drawer
        :title="$t.cargo_movements"
        placement="right"
        width="500"
        :closable="true"
        :visible="state.show_shipment_status_history"
        @close="state.show_shipment_status_history = false"
      >
        <a-row>
          <a-col :span="12">
            {{ $t.tstrackingNumber }}
          </a-col>
          <a-col
            :span="12"
          >
            {{ state.status_histories.length > 0 ? state.shipment_status_history.order_number : '-' }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            {{ $t.trackingNumber }}
          </a-col>
          <a-col
            :span="12"
          >
            {{ state.status_histories.length > 0 ? state.shipment_status_history.shipment_number : '-' }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            {{ $t.movements }}
          </a-col>
          <a-col :span="12">
            <ul v-if="state.status_histories.length > 0">
              <li
                v-for="(line, counter) in state.status_histories"
                :key="line.id"
              >
                {{ $t.state }}: {{ line.message ?? '-' }}
                <br>
                {{ $t.date }}: {{ line.date ?? '-' }}
                <br>
                {{ $t.time }}: {{ line.time ?? '-' }}
                <br>
                <div
                  v-if="state.status_histories.length !== counter + 1"
                  class="text-center"
                >
                  <DownSquareOutlined />
                </div>
                <br>
              </li>
            </ul>
            <span v-else> - </span>
          </a-col>
        </a-row>
      </a-drawer>
    </Main>
  </a-spin>
</template>

<script>

import {
  CloseOutlined,
  DownloadOutlined,
  DownSquareOutlined,
  OrderedListOutlined,
  SearchOutlined,
} from '@ant-design/icons-vue'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { prepareTableData } from '@/utility/utility'
import { message, Modal } from 'ant-design-vue'
import { t } from '@/utility/language'
import restMethods from '@/utility/restMethods'
import moment from 'moment'
import { Main } from '@/view/styled'
import { Pstates } from '@/view/dashboard/style'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TsoftKargoShipmentList',
  components: {
    Pstates,
    Main,
    CloseOutlined,
    DownloadOutlined,
    DownSquareOutlined,
    OrderedListOutlined,
    SearchOutlined,
  },
  setup() {

    const route = useRoute()

    const searchInput = ref()

    const state = reactive({
      cancel_submittings: [],

      shipment_print_url: '',
      shipments: [],
      status_histories: [],
      show_shipment_status_history: false,
      page_loading: true,
      details: {
        unApprovedCbm: 0,
        unApprovedPrice: 0,
        approvedCbm: 0,
        approvedPrice: 0,
        totalCbm: 0,
        totalPrice: 0,
      },
      printList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200'],
      },
      shipment_status_history: {
        order_number: '',
        shipment_number: '',
      },
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          state.printList = selectedRows.map(record => record.cargo_key)
        },
        getCheckboxProps: record => ({
          disabled: false,
          name: record.receiver_cust_name,
        }),
      },
      columns: [
        {
          title: t.name,
          dataIndex: 'receiver_cust_name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'receiver_cust_name_render',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus()
              }, 100)
            }
          },
        },
        {
          title: t.cargo,
          dataIndex: 'shipping_company_name',
          slots: {
            customRender: 'shipping_company_name_render',
          },
        },
        {
          title: t.order_date,
          dataIndex: 'created_at',
          slots: {
            customRender: 'created_at_render',
          },
          sorter: true,
        },
        {
          title: t.trackingNumber,
          dataIndex: 'request_number',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus()
              }, 100)
            }
          },
        },
        {
          title: t.orderNumber,
          dataIndex: 'cargo_key',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Durum',
          dataIndex: 'state_info', // filtre olarak backend'de kullanılıyor
          slots: {
            customRender: 'state_info_render',
          },
        },
        {
          title: t.trackingNo,
          dataIndex: 'tracking_no',
        },
        {
          title: t.trackingUrl,
          dataIndex: 'tracking_url',
          slots: {
            customRender: 'tracking_url_render',
          },
        },
        {
          title: t.shipping_label,
          dataIndex: 'barcode_link',
          slots: {
            customRender: 'barcode_link_render',
          },
        },
        {
          slots: {
            customRender: 'cargo_movements_render',
          },
        },
        {
          slots: {
            customRender: 'cancel_render',
          },
        },
      ],
    })

    const locale = computed(() => {
      return {
        emptyText: t.searchDontHaveAnyPost,
      }
    })

    const loadPageData = (tableData = undefined) => {

      tableData = typeof tableData === 'undefined' ? prepareTableData(state.pagination) : tableData

      state.page_loading = true
      restMethods.postForTSoftKargoWebApp('/get-shipment-list', {
        ...tableData,
      }).then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          state.shipment_print_url = data.shipment_print_url
          state.shipments = data.shipments
          state.pagination = data.pagination

          state.details.approvedCbm = data.details.approved_cbm
          state.details.approvedPrice = data.details.approved_price
          state.details.unApprovedCbm = data.details.un_approved_cbm
          state.details.unApprovedPrice = data.details.un_approved_price
          state.details.totalCbm = data.details.total_cbm
          state.details.totalPrice = data.details.total_price

          state.columns = state.columns.map((item) => {

            switch (item.dataIndex) {

              case 'state_info':

                item.filters = data.state_infos.map((item) => ({
                  text: item.title,
                  value: item.state,
                }))

                break

            }

            return item

          })

        }
        state.page_loading = false
      })
    }

    const handleTableChange = (page, filters, sorter) => {
      loadPageData(prepareTableData(page, filters, sorter))
    }

    const getPlaceholder = (dataIndex) => {
      const placeholders = {
        receiver_cust_name: t.searchCustomer,
        shipping_company_name: t.searchCargoCompany,
        created_at: t.searchOrderDate,
        request_number: t.searchTrackingNumber,
        cargo_key: t.searchOrderNumber,
        // Diğer sütunlar için benzer şekilde yer tutucuları ekleyebilirsiniz.
      }
      return placeholders[dataIndex] ?? `Search ${dataIndex}`
    }

    const goHref = (value) => {
      window.open(value, '_blank')
    }

    const singlePrint = (value) => {
      window.open(value + ('/' + localStorage.getItem('jwtToken')), '_blank')
    }

    const sendForPrint = () => {
      if (state.printList.length < 1) {
        message.error(t.atLeastOneRecordMustBeSelected)
        return
      }
      window.open(state.shipment_print_url + '/' + state.printList.join(',') + '/' + localStorage.getItem('jwtToken'), '_blank')
    }

    const cargoMovementClick = (value) => {
      state.status_histories = []
      restMethods.postForTSoftKargoWebApp('/get-shipment-status-history/' + value).then(response => {

        if ( ! response.hasError()) {

          const data = response.getData()

          state.shipment_status_history.order_number = data.order_number
          state.shipment_status_history.shipment_number = data.shipment_number
          state.status_histories = data.status_histories

          state.show_shipment_status_history = true

        }

      })
    }

    const cancelShipment = (shipmentId) => {
      Modal.confirm({
        title: 'Gönderi iptal edilecek!',
        content: 'Emin misiniz?',
        okText: 'Evet',
        okType: 'danger',
        cancelText: 'Hayır',
        onOk() {
          state.cancel_submittings[shipmentId] = true
          restMethods.postForTSoftKargoWebApp('/cancel-shipment/' + shipmentId).then(response => {

            if ( ! response.hasError()) {

              message.success(response.getData().message)

              loadPageData()

            }

            state.cancel_submittings[shipmentId] = false

          })
        },
      })
    }

    onMounted(() => {

      if (route.query.state) {
        loadPageData(prepareTableData(state.pagination, {
          state_info: [parseInt(route.query.state)],
        }))
      } else {
        loadPageData()
      }

    })

    return {
      state,

      moment,

      searchInput,

      locale,

      handleTableChange,
      getPlaceholder,
      goHref,
      singlePrint,
      sendForPrint,
      cargoMovementClick,
      cancelShipment,

    }

  },
})

</script>
<style scoped lang="scss">

.pst {

  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */

  div {
    flex: 1; /* Her bir öğe için eşit genişlik alır */
    text-align: center; /* İçerikleri merkezler */
  }

}

.sub {
  display: block;
}
</style>